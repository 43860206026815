export const formStatus = Object.freeze({
  NOT_STARTED: 0,
  STARTED: 1,
  REQUIRED_COMPLETED: 2,
  COMPLETED: 3
});
  
export const formStatusDisplay = {
  [formStatus.NOT_STARTED]: 'Not Started',
  [formStatus.STARTED]: 'Started',
  [formStatus.REQUIRED_COMPLETED]: 'Required Completed',
  [formStatus.COMPLETED]: 'Completed'
};

export const surveyStatusDisplay = {
  [formStatus.NOT_STARTED]: 'Not Started',
  [formStatus.STARTED]: 'Started',
  [formStatus.COMPLETED]: 'Submitted'
};

export const formStatusSelect = Object.keys(formStatusDisplay).map(key => ({ value: Number(key), text: formStatusDisplay[key] }));

export const surveyStatusSelect = Object.keys(surveyStatusDisplay).map(key => ({ value: Number(key), text: surveyStatusDisplay[key] }));
