export const userRoles = Object.freeze({
  ADMIN: 1,
  CHAMPION: 2,
  MENTOR: 3,
  MENTEE: 4,
  CHAMPION_CANDIDATE: 5,
  PARTICIPANT_CANDIDATE: 6,
  PROGRAM_ADMIN: 7,
  PROGRAM_ADMIN_CANDIDATE: 8
});
  
export const userRolesDisplay = {
  [userRoles.ADMIN]: 'Administrator',
  [userRoles.CHAMPION]: 'Champion',
  [userRoles.MENTOR]: 'Mentor',
  [userRoles.MENTEE]: 'Mentee',
  [userRoles.CHAMPION_CANDIDATE]: 'Champion Candidate',
  [userRoles.PARTICIPANT_CANDIDATE]: 'Participant Candidate',
  [userRoles.PROGRAM_ADMIN]: 'Program Admin',
  [userRoles.PROGRAM_ADMIN_CANDIDATE]: 'Program Admin Candidate',
};

export const userRolesDisplaySelect = [
  { value: userRoles.ADMIN, text: 'Administrator'},
  { value: userRoles.CHAMPION, text: 'Champion'},
  { value: userRoles.MENTOR, text: 'Mentor'},
  { value: userRoles.MENTEE, text: 'Mentee'},
  { value: userRoles.CHAMPION_CANDIDATE, text: 'Champion Candidate'},
  { value: userRoles.PARTICIPANT_CANDIDATE, text: 'Participant Candidate'}
];

// Vue goodtable select dropdown
export const userRolesDisplayFilter = [
  { value: userRoles.ADMIN, text: 'Administrator'},
  { value: userRoles.CHAMPION, text: 'Champion'},
  { value: userRoles.MENTOR, text: 'Mentor'},
  { value: userRoles.MENTEE, text: 'Mentee'},
];
export const participantRolesDisplay = [
  { value: userRoles.MENTEE, text: 'Mentee'},
  { value: userRoles.MENTOR, text: 'Mentor' }
];
export const allParticipantRolesDisplay = [
  { value: userRoles.MENTEE, text: 'Mentee'},
  { value: userRoles.MENTOR, text: 'Mentor' },
  { value: userRoles.PARTICIPANT_CANDIDATE, text: 'Participant Candidate' }
];

export const championRolesDisplay = [
  { value: userRoles.CHAMPION, text: 'Champion'},
  { value: userRoles.PROGRAM_ADMIN, text: 'Program Admin' }
];

export const adminRolesDisplay = [
  { value: userRoles.ADMIN, text: 'Admin'},
  { value: userRoles.PROGRAM_ADMIN_CANDIDATE, text: 'Program Admin Candidate' }
];
  